.eachClientBook {
  display: flex;
  gap: 2em;
  margin-bottom: 2em;
  cursor: pointer;
  font-size: 18px;
}

.eachClientBookImageContainer {
  width: 230px;
  height: 260px;
}

.eachClientBookImageContainer img {
  width: 230px;
  height: 260px;
  object-fit: cover;
}

.eachClientBookTitleStyle {
  font-weight: bold;
  font-size: 32px;
  text-transform: capitalize;
}

.eachClientBookPublishedDateStyle {
  color: #3e7ab1;
}

.eachClientBookDescriptionStyle {
  margin-top: 1em;
  text-align: justify;
}

.bookDetailTitleContainer {
  margin-top: 6em;
  width: 100%;
  background-color: #0c4477;
}

.bookDetailTitleContainer div {
  font-size: 30px;
  color: #ffffff;
  text-transform: capitalize;
  padding-top: 1em;
  padding-bottom: 1em;
}

.bookDetailsMainContainerDesc {
  display: flex;
  margin-top: 5em;
  margin-bottom: 5em;
  padding-left: 1vw;
  padding-right: 1vw;
  justify-content: space-between;
  width: 100%;
}

.bookDetailsWhatWillYouLearnContainer {
  text-align: left;
  display: flex;
  flex-direction: column;
  width: 70%;
}

.bookDetailsWhatYouWillLearnStyle {
  font-size: 18px;
  margin-top: 1em;
  text-align: justify;
}

.bookDetailsMainImageMainContainer {
  width: 21.75%;
}

.detailBookImageContainer {
  width: 100%;
}

.detailBookImageContainer img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.downloadBookContainer {
  background-color: #ff8210;
  cursor: pointer;
  margin-top: 1em;
}

.downloadBookContainer div {
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  font-size: 17px;
  font-weight: bold;
  color: #ffffff;
  text-decoration: none;
}

@media only screen and (max-width: 680px) {
  .bookDetailsMainContainerDesc {
    display: flex;
    flex-direction: column;
    margin-top: 5em;
    margin-bottom: 5em;
    padding-left: 0;
    padding-right: 0;
    align-items: center;
    width: 100%;
  }

  .bookDetailsMainImageMainContainer {
    width: 50%;
    margin-bottom: 2em;
  }

  .eachClientBook {
    display: flex;
    flex-direction: column;
    gap: 2em;
    margin-bottom: 5em;
    cursor: pointer;
  }

  .eachClientBookImageContainer {
    display: flex;
    width: 100%;
    height: 390px;
    align-items: center;
    justify-content: center;
  }

  .eachClientBookImageContainer img {
    width: 315px;
    height: 390px;
    object-fit: cover;
  }

  .bookDetailsMainImageMainContainer {
    width: 90%;
  }

  .bookDetailsWhatWillYouLearnContainer {
    text-align: left;
    display: flex;
    flex-direction: column;
    width: 90%;
  }
}
