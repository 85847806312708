/* Hide reCAPTCHA */
.grecaptcha-badge {
    visibility: hidden;
}

/*  */

.contactContainer {
    padding-top: 120px;
    display: flex;
    justify-content: space-around;
}

.contactBannerContainer {
    text-align: left;
    width: 30%;
    padding-bottom: 20px;
}

.contactBannerTitle {
    font-size: 55px;
    font-weight: 700;
}

.contactBannerSubTitle {
    margin-top: 67px;
    margin-bottom: 87px;
    font-size: 40px;
    color: #1f81dc;
}

.contactMainTitle {
    color: #0D4C86;
    font-size: 20px;
    font-weight: 700;
}

.contactHeadOfficeEmail span {
    font-weight: 700;
}

.contactWhatsApp {
    margin-top: 84px;
}

.contactWhatsAppButton a {
    color: white;
    text-decoration: none;
    background-color: #2aaada;
    padding: 10px;
    padding-left: 1em;
    padding-right: 1em;
    border-radius: 25px;
}

.contactWhatsAppButton a:hover {
    color: white;
    background-color: #0D4C86;
}

.contactWhatsAppButton a:hover img {
    filter: invert(75%) sepia(25%) saturate(7082%) hue-rotate(162deg) brightness(150%) contrast(89%);
}

.contactFormContainer {
    margin-top: 300px;
    text-align: left;
    width: 50%;
    margin-bottom: 200px;
}

.contactFormSection {
    margin-top: 30px;
}

.contactFormSection label {
    font-weight: 600;
    margin-bottom: 10px;
}

.contactFormSection input {
    border: 1px solid black;
    font-size: 13px;
    padding: 10px;
    border-radius: 10px;
    width: 100%;
}

.contactFormSection textarea {
    border: 1px solid black;
    width: 100%;
    font-size: 13px;
}

.contactFormSection p {
    padding-top: 5px;
    font-size: 13px;
    color: red;
}

.contactFormCheckbox {
    margin-top: 37px;
    margin-bottom: 37px;
}

.contactFormCheckbox input {
    margin-right: 10px;
    margin-top: 10px;
    transform: scale(1.2);
}

.contactFormCheckbox span {
    font-size: 13px;
}

.contactFormSubmit {
    margin-top: 20px;
}

.contactFormSubmit button {
    margin-top: 20px;
    margin-bottom: 20px;
    border-radius: 0px;
}

.contactFormSubmit button:hover {
    border-color: #104c84;
    background-color: #104c84;
    color: white;
}

.contactFormSubmit button:hover img {
    filter: invert(100%);
    -webkit-filter: invert(100%);
    transition: .3s ease;
}

@media only screen and (max-width: 820px) {
    .contactContainer {
        margin: 3em;
        flex-direction: column;
    }

    .contactBannerSubTitle {
        margin-top: 1em;
        margin-bottom: 1em;
    }

    .contactBannerContainer,
    .contactFormContainer,
    .contactFormSection input,
    .contactFormSection textarea {
        width: 100%;
        /* max-width: 80%; */
    }

    .contactFormContainer {
        margin-top: 3em;
        margin-bottom: 3em;
    }

    .contactWhatsApp {
        margin-top: 3em;
    }

    .contactWhatsAppButton a {
        padding-left: 2em;
        padding-right: 2em;
    }
}

@media only screen and (max-width: 500px) {

    .contactBannerTitle {
        font-size: 1.875em;
    }

    .contactBannerSubTitle {
        font-size: 1.875em;
    }
}