.detailBlogContainer {
    padding-top: 200px;
    text-align: left;
    margin: 0 136px 0 136px;
}

.detailBlogMainTitle {
    font-size: 55px;
    font-weight: 700;
    color: #0d4c86;
}

.detailBlogDate {
    font-size: 18px;
}

.detailBlogHtmlContent {
    word-wrap: break-word;
}

.detailBlogHtmlContent p, .detailBlogHtmlContent span, .detailBlogHtmlContent li {
    font-size: 20px !important;
}

.detailBlogHtmlContent img {
    max-width: 100%;
    object-fit: contain;
}

.detailBlogSocialMedia {
    display: flex;
    margin-bottom: 82px;
    margin-top: 1em;
}

.right {
    justify-content: flex-end;
}

.left {
    justify-content: flex-start;
}

.detailUpdates {
    background-color: #fafafa;
}

.detailUpdatesContainer {
    padding-top: 50px;
}

.detailUpdatesMainTitle {
    font-size: 40px;
    color: #1f81dc;
}

.detailUpdatesCardContainer {
    text-align: left;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-left: 69px;
    margin-right: 69px;
}

.detailUpdatesCardEach {
    display: flex;
    flex-direction: column;
    max-width: 400px;
    margin: 20px;
}

.detailUpdatesCardTitle {
    margin-top: 30px;
    margin-bottom: 15px;
    cursor: pointer;
}

.detailUpdatesCardTitle p {
    font-size: 25px;
    font-weight: 700;
    color: #0d4c86;
    text-decoration: none;
    display: inline-block;
}

.detailUpdatesCardTitle p:hover {
    color: #FF9F1E;
}

.detailUpdatesCardTitle p::after {
    content: '';
    width: 0px;
    height: 2px;
    display: block;
    background: #FF9F1E;
    transition: 300ms;
}

.detailUpdatesCardTitle p:hover::after {
    width: 100%;
}

.detailUpdatesCardImage img {
    width: 100%;
    min-height: 270px;
    height: auto;
    object-fit: cover;
}

.detailUpdatesCardDate {
    font-size: 13px;
}


@media only screen and (max-width: 500px) {

    .detailBlogContainer {
        padding-top: 200px;
        text-align: left;
        margin: 0 50px 0 50px;
    }

    .detailBlogMainTitle {
        font-size: 24px;
    }

    .detailBlogDate {
        font-size: 14px;
    }

    .detailBlogSocialMedia {
        margin-bottom: 3em;
    }

    .detailBlogHtmlContent p {
        font-size: 14px;
    }

    .detailBlogHtmlContent img {
        max-width: 300px;
    }

    .detailUpdatesMainTitle {
        font-size: 24px;
    }

    .detailUpdatesCardContainer {
        margin-left: 1em;
        margin-right: 1em;
    }

    .detailUpdatesCardTitle p {
        font-size: 20px;
    }

    .right {
        justify-content: flex-start;
    }
}