#blog {
  padding-top: 50px;
}

.blogContainer {
  display: flex;
  flex-direction: column;
  padding-bottom: 50px;
}

.blogBannerContainer {
  position: relative;
  width: 100%;
}

.blogBannerContainer img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: brightness(50%);
}

.blogBannerText {
  position: absolute;
  display: flex;
  text-align: left;
  max-width: 1440px;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.blogBannerTitle {
  font-size: 55px;
  font-weight: 700;
  color: #ff9f1e;
  width: 100%;
  margin-left: 4em;
}

.blogBannerSubtitle {
  width: 100%;
  margin-right: 4em;
  font-size: 20px;
  font-weight: 700;
  color: white;
  line-height: 40px;
}

.blogFunctionButton {
  margin-top: 6em;
  margin-left: 7em;
}

.blogFunctionButtonContainer {
  display: flex;
  flex-direction: row;
}

.overlayBlogButton {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.6);
  transition: opacity 500ms;
  visibility: hidden;
  z-index: 1;
  opacity: 1;
}

.ButtonListContainer {
  padding: 0.8em 0 0.8em 0;
  width: 100%;
  max-width: 270px;
}

/* .ButtonListContainer hr {
    border: 1px solid black;
    height: 0.5px;
    opacity: 1;
} */

.ButtonListBrowse {
  background-color: white;
  visibility: hidden;
  position: absolute;
  text-align: left;
  padding-right: 1em;
  border-radius: 1.5em;
  margin-top: 1em;
  z-index: 1;
}

.ButtonListSort {
  background-color: white;
  visibility: hidden;
  position: absolute;
  text-align: left;
  padding-right: 1em;
  border-radius: 1.5em;
  margin-top: 1em;
  z-index: 1;
}

.ButtonListSort img {
  margin-right: 0.5em;
}

.ButtonListTitle {
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-weight: 700;
}

.ButtonListTitle p {
  margin-left: 1em;
}

.ButtonChildrenList {
  margin-left: 1em;
}

.ButtonChildrenList input {
  margin-bottom: 1em;
}

.ButtonChildrenList p {
  cursor: pointer;
  display: inline-block;
}

.ButtonChildrenList p::after {
  content: "";
  width: 0px;
  height: 1px;
  display: block;
  background: black;
  transition: 300ms;
}

.ButtonChildrenList p:hover::after {
  width: 100%;
}

/* Blog Button */
.blogFunctionButtonContent button {
  border: 1px solid black;
  padding: 1em;
  border-radius: 0.5em;
  font-size: 0.8em;
  margin-right: 1em;
}
/*  */

.blogCardContainer {
  margin-top: 5em;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  text-align: left;
  padding: 0 2em;
}

.blogCardEach {
  max-width: 300px;
  margin: 0 3.2em 3.2em 3.2em;
}

.founderStoryExploreMoreEach {
  max-width: 350px;
  margin: 0 1.25vw 3.2em 1.25vw;
}

.blogCardPicture {
  max-width: 500px;
  max-height: 330px;
  overflow: hidden;
  cursor: pointer;
}

.blogCardPicture img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s ease;
}

.blogCardPicture:hover {
  border-bottom: 5px solid #ff9f1e;
}

.blogCardPicture:hover img {
  transform: scale(1.5);
  object-fit: cover;
}

.blogCardTitle {
  margin: 29px 0px 19px 0px;
}

.blogCardTitle a {
  font-size: 35px;
  font-weight: 700;
  color: #c55e24;
  text-decoration: none;
  display: inline-block;
}

.blogCardTitle a:hover {
  color: #ff9f1e;
}

.blogCardTitle a::after {
  content: "";
  width: 0px;
  height: 2px;
  display: block;
  background: #ff9f1e;
  transition: 300ms;
}

.blogCardTitle a:hover::after {
  width: 100%;
}

.blogCardEach:hover .blogCardPicture {
  border-bottom: 5px solid #ff9f1e;
}

.blogCardEach:hover .workshopCardPicture {
  border-bottom: 5px solid #ff9f1e;
}

.blogCardEach:hover img {
  object-fit: cover;
}

.blogCardMethod {
  display: flex;
  flex-direction: row;
  margin-top: 30px;
}

.blogCardDate {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
}

.filterContent {
  margin: 0 3.2em 3.2em 3.2em;
  display: flex;
  flex-direction: row;
}

.searchBarContainerWorkshop {
  margin-left: 2em;
  width: 504px;
  height: 60px;
  background: #f5f5f5;
  border-radius: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.searchBarContainerWorkshop img {
  margin-left: 1.5em;
  width: 30px;
  height: 30px;
}

.searchBarContainerWorkshop input {
  margin-left: 1em;
  font-size: 18px;
  background-color: #f5f5f5;
  border-color: transparent;
  outline: none;
  /* display: flex;
    flex-direction: row;
    margin-top: 20px; */
}

.blogCardLocation {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
}

.blogCardMethod img {
  width: 25px;
  height: 25px;
}

.blogCardDate img {
  width: 25px;
  height: 25px;
}

.blogCardLocation img {
  width: 23.5px;
  height: 30px;
}

.blogCardMethod div {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.blogCardDate div {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.blogCardLocation div {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.blogCardTags {
  font-size: 17px;
  font-weight: 600;
}

.blogCardTagsBoxContainer {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
}

.blogCardTagsBox {
  display: flex;
  flex-direction: row;
  border-radius: 30px;
  background-color: #eeebeb;
  justify-content: center;
  align-items: center;
  margin-right: 0.5em;
}

.blogCardTagsBox p {
  margin: 0.5em 1em 0.5em 1em;
}

.filterOptions {
  display: flex;
  flex-direction: column;
  margin: 0 2em 3.2em 3.2em;
}

.filterOptionsContainer {
  display: flex;
  flex-direction: row;
  margin-left: -1em;
}

.filterButton {
  width: 165px;
  height: 60px;
  background: #f5f5f5;
  border-radius: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-right: 0.5em;
  border-color: transparent;
}

.clickedFilterButton {
  width: 165px;
  height: 60px;
  background: #0d4a83;
  border-radius: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-right: 0.5em;
  border-color: transparent;
}

.sortByButton {
  width: 165px;
  height: 60px;
  background: #f5f5f5;
  border-radius: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: 0.5em;
  border-color: transparent;
}

.clickedSortbyButton {
  width: 165px;
  height: 60px;
  background: #0d4a83;
  border-radius: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: 0.5em;
  border-color: transparent;
}

.filterButtonImg {
  width: 25px;
  height: 25px;
}

.sortByButtonImg {
  width: 25px;
  height: 25px;
}

.filterButtonDiv {
  margin-left: 1em;
}

.clickedFilterButtonDiv {
  margin-left: 1em;
  color: #ffffff;
}

.sortbyButtonDiv {
  margin-left: 1em;
}

.clickedSortbyButtonDiv {
  margin-left: 1em;
  color: #ffffff;
}

.dropdownFilterButton {
  background-color: #eeebeb;
  border-radius: 20px;
  position: absolute;
  z-index: 1;
  margin-top: 1em;
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-left: -1em;
}

.filterBySubject {
  margin-left: 2em;
  margin-right: 2em;
  margin-top: 0.75em;
  margin-bottom: 0.75em;
  font-weight: 700;
}

.horizontalLine {
  border-top: 0.7px solid black;
  border-bottom: 0.7px solid black;
}

.optionsFilterBtn {
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-top: 1em;
  margin-left: 2em;
  margin-right: 2em;
}

.optionsSortbyBtn {
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-top: 0.5em;
  margin-left: 1.5em;
  margin-right: 1.5em;
  margin-bottom: 0.5em;
  border-color: transparent;
}

.eachInput {
  display: flex;
  flex-direction: row;
  margin-bottom: 1em;
}

.eachInputField {
  border: 1px solid #000000;
  background-color: #f5f5f5;
  border-color: transparent;
  outline: none;
}

.eachInput label {
  margin-left: 1em;
}

.dropdownSortbyButton {
  width: 305px;
  background-color: #eeebeb;
  border-radius: 20px;
  position: absolute;
  z-index: 1;
  margin-top: 1em;
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-left: -1em;
}

.filterContent {
  margin: 0 3.2em 3.2em 3.2em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

@media only screen and (max-width: 1000px) {
  .blogCardContainer {
    margin-top: 6em;
    display: flex;
    justify-content: center;
    text-align: left;
    padding: 0 2em;
  }

  .filterOptions {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .filterContent {
    margin: 0 3.2em 3.2em 3.2em;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  .searchBarContainerWorkshop {
    margin-left: 0em;
    width: 390px;
    height: 60px;
    background: #f5f5f5;
    border-radius: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .blogFunctionButtonContent button {
    font-size: 0.6em;
  }

  .filterOptionsContainer {
    display: flex;
    flex-direction: row;
  }
}

@media only screen and (max-width: 820px) {
  .blogCardContainer {
    margin-top: 6em;
    display: flex;
    justify-content: center;
    text-align: left;
    padding: 0 2em;
  }

  .filterOptions {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .filterContent {
    margin: 0 3.2em 3.2em 3.2em;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  .searchBarContainerWorkshop {
    margin-left: 0em;
    width: 390px;
    height: 60px;
    background: #f5f5f5;
    border-radius: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .filterOptionsContainer {
    display: flex;
    flex-direction: row;
    margin-top: 2em;
  }
}

@media only screen and (max-width: 750px) {
  .filterOptions {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .filterContent {
    margin: 0 3.2em 3.2em 3.2em;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  .searchBarContainerWorkshop {
    margin-left: 0em;
    width: 390px;
    height: 60px;
    background: #f5f5f5;
    border-radius: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .filterOptionsContainer {
    display: flex;
    flex-direction: row;
    margin-top: 2em;
  }

  .blogCardEach {
    margin-top: -5em;
    max-width: 250px;
    text-align: left;
    margin-right: 12em;
    margin-bottom: 7.5em;
  }
}

@media only screen and (max-width: 500px) {
  .blogCardEach {
    margin-top: 2em;
    max-width: 100%;
    text-align: left;
    display: flex;
    flex-direction: column;
    margin: 3.2em 3.2em 3.2em 3.2em;
  }

  .blogBannerTitle {
    margin-top: 2.5em;
    color: white;
    font-size: 1.875em;
    width: 100%;
    margin-left: 1em;
  }

  .blogBannerSubtitle {
    display: none;
  }

  .blogFunctionButton {
    margin-top: 2em;
    margin-left: 3em;
  }

  .ButtonListContainer {
    width: 12em !important;
  }

  .blogCardTitle a {
    font-size: 18px;
  }

  .founderStoryCardTitle a {
    font-size: 25px;
  }

  .blogCardSubtitle {
    font-size: 14px;
  }

  .ButtonListSort {
    left: 3em;
  }

  .blogFunctionButtonContent button {
    font-size: 0.6em;
  }

  .blogCardContainer {
    margin-top: 6em;
    display: flex;
    justify-content: center;
    text-align: left;
    padding: 0 2em;
  }

  .filterOptions {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .filterContent {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .searchBarContainerWorkshop {
    width: 390px;
    height: 60px;
    background: #f5f5f5;
    border-radius: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .searchBarContainerWorkshop input {
    width: 275px;
    height: 60px;
    background: #f5f5f5;
    border-radius: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .filterOptionsContainer {
    display: flex;
    flex-direction: row;
    margin-top: 2em;
  }

  .blogBannerContainer {
    position: relative;
    width: 100%;
    margin-top: 4.5em;
  }
}
