#home {
  overflow: hidden;
  margin: auto;
}

/* Reusable Props */
.webTitle {
  padding-top: 50px;
  font-size: 55px;
  font-weight: 700;
  margin: 0px 195px 55px 195px;
  color: #1f81dc;
}

.buttonViewAll {
  margin-top: 50px;
}

.buttonViewAll a {
  text-decoration: none;
  color: black;
  padding: 10px;
  padding-left: 30px;
  padding-right: 30px;
  transition: 0.5s ease;
}

.buttonViewAll a:hover {
  background-color: black;
  border-color: black;
  color: white;
  transition: 0.5s ease;
}

/* Navigation Bar */

.navigationBarMainContainer {
  position: fixed;
  z-index: 2;
  width: 100%;
  box-shadow: 0px 8px 30px rgba(0, 0, 0, 0.21);
  background-color: white;
}

.navigationBarContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

img.navigationBarLogo {
  width: 20em;
  height: 50px auto;
  cursor: pointer;
  margin-left: 0;
  margin-right: 4vw;
}

.navigationBarMenuContainer ul {
  padding-top: 10px;
}

.navigationBarMenuContainer ul li {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 4em;
}

.navigationBar ul li {
  border-radius: 0px;
}

.navLink a {
  text-decoration: none;
  color: black;
}

.navigationBarMenuContainer li:hover a {
  color: #1f81dc;
}

.navigationBarMenuContainer ul li {
  border-top: none;
  border-left: none;
  border-right: none;
  border-radius: 0px;
  margin-bottom: 0px;
  cursor: pointer;
  font-size: 1em;
  border-bottom: 3px solid transparent;
}

.navigationBarMenuContainer li:hover {
  border-bottom: 3px solid #ff9f1e;
}

.navigationBarLoginContainer ul li {
  padding-bottom: 20px;
  padding-left: 1px;
  padding-right: 5px;
}

.navigationBarLogin {
  margin-right: 20px;
  padding-top: 5px;
  font-size: 12px;
}

.navigationBarDummy {
  width: 75%;
}

.aboutUsList {
  background-color: #0c4477;
  display: none;
  position: absolute;
  text-align: left;
}

.angleDropdown {
  margin-left: 5px;
}

.fa-angle-down {
  transition: 0.5s ease;
}

.navigationBarAbout:hover .aboutUsList {
  display: block;
  text-align: left;
}

.navigationBarAbout:hover .fa-angle-down {
  transition: 0.5s ease;
  color: #1f81dc;
  transform: scaleY(-1);
  -webkit-transform: scaleY(-1);
  -ms-transform: scaleY(-1);
}

.aboutUsList li {
  color: white;
}

.aboutUsList li:hover {
  border: 1px solid transparent;
  font-weight: 700;
}

/* Home Banner */
#homeBanner {
  position: relative;
  color: white;
  width: 100%;
  height: 100%;
}

#homeBanner img {
  width: 100%;
  height: 840px;
  object-fit: cover;
  /* filter: brightness(70%); */
}

.textBanner {
  position: absolute;
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-left: 135px;
  bottom: 15vw;
}

.textBannerMain {
  font-size: 55px;
  font-weight: 700;
}

.textBannerSub {
  font-size: 30px;
  font-weight: 400;
}

.textBanner button {
  margin-top: 40px;
  border-radius: 0px;
  font-size: 20px;
  padding: 15px;
}

.textBanner button:hover {
  border-radius: 0px;
  border-color: #104c84;
  background-color: #104c84;
  color: white;
}

/* Vision and Goal */
#visionAndGoal {
  margin-bottom: 100px;
  margin-top: 100px;
}

.visionAndGoalText {
  color: #104c84;
  font-size: 40px;
  font-weight: 700;
  text-align: left;
  margin: 60px 135px 40px 135px;
}
.visionAndGoalTextMain {
  color: #1f81dc;
}

/* Side Banner */
#sideBanner {
  display: flex;
  flex-direction: column;
  background-color: #fafafa;
}

.sideBannerLeft {
  display: flex;
  justify-content: center;
  margin-bottom: 60px;
  max-height: 2000px;
  overflow: hidden;
}

.sideBannerLeftContainer {
  display: flex;
  justify-content: space-between;
}

.sideBannerLeftText {
  margin: 4.3em;
  text-align: left;
  max-width: 75%;
}

.sideBannerLeft img {
  width: 100%;
  object-position: top;
  object-fit: cover;
}

.sideBannerRight {
  display: flex;
  flex-direction: row-reverse;
  max-height: 2000px;
  overflow: hidden;
}

.sideBannerRightContainer {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
}

.sideBannerRightText {
  margin: 4.3em;
  text-align: left;
  max-width: 75%;
}

.sideBannerRight img {
  width: 100%;
  object-fit: cover;
  object-position: top;
}

.sideBannerTextMain {
  font-size: 35px;
  font-weight: 700;
  color: #0d4c86;
}

.sideBannerTextSub {
  font-size: 20px;
  color: #1f81dc;
  line-height: 40px;
}

.sideBannerTextLink {
  text-decoration: none;
  color: black;
}

/* Card Programs */

#programs {
  background-color: #fafafa;
  padding-top: 7.188em;
}

.programsTitle {
  font-size: 55px;
  font-weight: 700;
  margin: 115px 195px 62px 195px;
  color: #1f81dc;
}

.programsCard {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-bottom: 86px;
}

.programsEachCard {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px;
  max-width: 38em;
  max-height: 300px;
  overflow: hidden;
  border-radius: 10px;
  transition: 0.5s ease;
  cursor: pointer;
}

.programsEachCard:hover {
  box-shadow: 0px 8px 30px rgba(0, 0, 0, 0.21);
  transition: 0.5s ease;
}

.programsEachCard img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  filter: brightness(50%);
  border-radius: 10px;
  transition: transform 0.5s ease;
}

.programsEachCard:hover img {
  transform: scale(1.5);
  object-fit: cover;
}

.programsEachCard p {
  font-size: 25px;
  font-weight: 600;
  max-width: 400px;
  position: absolute;
  color: white;
  cursor: pointer;
}

.programsEachCardSmallText {
  font-size: 15px;
  font-weight: 400;
}

/* Events */
#events {
  margin: 0 5.5em 7.2em 5.5em;
  display: flex;
  flex-direction: column;
}

/* Events Pagination */
.eventsPagination ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.eventsPagination li {
  float: left;
  height: 100%;
  max-height: 30px;
}

.eventsPagination a.page-link {
  display: none;
}

ul.slick-dots {
  visibility: hidden;
}
/*  */

.eventsController {
  display: flex;
  justify-content: space-between;
}

.eventsControllerFilter button {
  border: 1px solid black;
  border-radius: 5px;
  padding-right: 30px;
  margin-bottom: 24px;
}

.eventsControllerFilter img {
  margin-right: 10px;
}

.eventsControllerPagination {
  display: flex;
  align-items: center;
}

.eventsControllerPagination a {
  color: black;
  margin-right: 25px;
}

.eventsControllerPaginationArrowLeft img {
  margin: 0 10px 0 1px;
  border-radius: 100%;
  border: 1px solid black;
  padding: 8px 10px 8px 10px;
  transition: 0.2s ease;
}

.eventsControllerPaginationArrowLeft img:hover {
  background-color: white;
  filter: invert(100%);
  -webkit-filter: invert(100%);
  transition: 0.2s ease;
}

.eventsControllerPaginationArrowRight img {
  transform: rotate(180deg);
  border-radius: 100%;
  border: 1px solid black;
  padding: 8px 10px 8px 10px;
  transition: 0.2s ease;
}

.eventsControllerPaginationArrowRight img:hover {
  background-color: white;
  filter: invert(100%);
  -webkit-filter: invert(100%);
  transition: 0.2s ease;
}

.eventsCard {
  display: flex;
  justify-content: space-between;
}

.eventsPagination {
  margin-right: 30px;
}

.eventsEachCard {
  display: flex;
  flex-direction: column;
  max-width: 400px;
  margin-right: 20px;
}

.eventsEachCardImageContainer {
  max-width: 400px;
  max-height: 200px;
  border-radius: 20px;
  overflow: hidden;
}

.eventsEachCardImageContainer img {
  width: 100%;
  height: auto;
  object-fit: contain;
}

.eventsEachCardName {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 14px;
}

.eventsEachCardName p {
  text-align: left;
  margin-right: 30px;
  margin-bottom: 0;
  font-size: 15px;
  font-weight: 700;
}

.eventsEachCardName button {
  font-size: 12px;
  border: 1px solid black;
  border-radius: 50px;
}

.eventsEachCardSubtitle {
  margin-top: 10px;
  font-size: 15px;
  text-align: left;
}

.eventsEachCardReviews {
  display: flex;
  align-items: center;
}

.eventsEachCardReviewsImage {
  margin: 0 15px 0 0;
}

.eventsEachCardReviewsLink a {
  color: black;
  margin-bottom: 0;
  font-size: 12px;
}

.eventsEachCarButton button {
  margin-top: 32px;
  display: flex;
  border-radius: 0px;
}

/* Small Banner - Mentorship */
#mentorship {
  background: linear-gradient(90deg, #11998e 0%, #38ef7d 100%);
}

.mentorshipContainer {
  padding: 27px 129px 40px 85px;
}

.mentorshipCollaboration {
  display: flex;
  color: white;
  font-size: 11px;
}

.mentorshipCollaboration img {
  margin: 5px 0 40px 15px;
}

.mentorshipMain {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.mentorshipMainText p {
  color: white;
  font-size: 40px;
  font-weight: 700;
  margin: 0;
}

.mentorshipMainButton button {
  background-color: #0d4c86;
  color: white;
  font-size: 20px;
  border-radius: 0px;
  padding: 5px 30px 5px 30px;
}

.mentorshipMainButton button:hover {
  background-color: white;
  color: #0d4c86;
  box-shadow: 0px 8px 30px rgba(0, 0, 0, 0.21);
}

.mentorshipSubtext {
  display: flex;
  color: white;
}

/* News Updates */
#news {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 65px;
  background-color: #fafafa;
}

.newsCards {
  display: flex;
  flex-wrap: wrap;
  margin: 2.2em;
  justify-content: flex-start;
  gap: 3em;
}

.newsEachCard {
  position: relative;
  display: flex;
  align-items: flex-end;
  width: 100%;
  height: 400px;
  max-width: 23em;
  max-height: 21.9em;
  box-shadow: 0px 8px 30px rgba(0, 0, 0, 0.21);
  border-radius: 20px;
  margin: 20px;
  overflow: hidden;
}

.newsEachCard img {
  border-radius: 20px;
  width: 100%;
  min-height: 100%;
  object-fit: cover;
  filter: brightness(50%);
}

.newsEachCardText {
  position: absolute;
  width: 100%;
  text-align: left;
  transition: 0.5s ease;
}

.newsEachCardText p {
  color: black;
  font-size: 20px;
  font-weight: 700;
  cursor: pointer;
  background-color: white;
  margin-bottom: 0;
  padding: 30px 45px 35px 30px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  transition: 0.5s ease;
}

.newsEachCard p:hover {
  color: white;
  background: rgba(22, 115, 145, 0.7);
}

.newsEachCardTextSub {
  margin-top: 10px;
  font-size: 18px;
  font-weight: 400;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.15s ease-in-out;
}

.newsEachCardText:hover .newsEachCardTextSub {
  max-height: 200px;
  transition: max-height 0.25s ease-out;
}

.newsArrow {
  display: flex;
  justify-content: flex-end;
}

/* Clients */
#clients {
  padding-bottom: 50px;
}

.clientsLogo {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-left: 200px;
  margin-right: 200px;
}

.clientsLogoContainer {
  max-width: 300px;
  width: 30%;
  height: auto;
  padding: 15px;
}

.clientsLogoContainer img {
  object-fit: contain;
  max-height: 120px;
  width: 100%;
  height: 100%;
}

/* Videos */
#videos {
  padding-bottom: 65px;
  background-color: #fafafa;
}

.videosSubtitle {
  font-size: 2.2em;
  font-weight: 400;
  line-height: 45px;
  margin: 0 0 95px 0;
  color: #0d4c86;
}

.videosCard {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.videosEachCard {
  margin: 19px;
}

.videosResponsive {
  width: 560px;
  height: 320px;
  border: none;
}

.videosExploreMore {
  margin-top: 77px;
}

#videos a {
  background-color: transparent;
  padding: 8px 15px 10px 20px;
  transition: 0.5s ease;
  text-decoration: none;
  color: black;
}

#videos a:hover {
  background-color: black;
  border-color: black;
  color: white;
  transition: 0.5s ease;
}

#videos a:hover img {
  filter: invert(100%);
  -webkit-filter: invert(100%);
  transition: 0.5s ease;
}

#videos img {
  margin-left: 10px;
  transition: 0.5s ease;
}

#videos img:hover {
  filter: invert(100%);
  -webkit-filter: invert(100%);
  transition: 0.5s ease;
}

/* Footer */

#footer {
  margin-left: auto;
  margin-right: auto;
}

.footerContainer {
  position: absolute;
  width: 100%;
}

.footerLine hr {
  margin-bottom: 0px;
  margin-top: 0px;
}

.footerUnGlobalImpact {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 50px;
}

.footerUnGlobalImpact img {
  width: 15%;
}

.footerUnGlobalImpact p {
  margin: 20px;
  font-size: 18px;
  color: #767676;
}

.footerNavigationContainer {
  background-color: #0c4477;
  color: white;
}

.followUsOnSMContainer {
  font-size: 16px;
}

.fullWidthNavbarFollowUsOnSocialMedia {
  width: 100%;
  padding-left: 12vw;
  padding-right: 12vw;
  background-color: #0c4477;
  color: white;
}

.navbarFollowUsOnSocialMediaContainer {
  display: flex;
  text-align: left;
  padding-top: 2.5vh;
  padding-bottom: 2.5vh;
  gap: 2vw;
  align-items: center;
  max-width: 1440px;
  width: 100%;
  margin: 0;
}

.backToTop {
  display: flex;
  text-align: left;
  margin-left: 35px;
  padding-top: 32px;
}

.backToTop a {
  color: white;
  text-decoration: none;
  margin-left: 10px;
  font-size: 12px;
}

.footerNavigationMain {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  text-align: left;
  max-width: 1440px;
}

.footerNavigationMainList {
  display: flex;
  flex-wrap: wrap;
  margin-top: 51px;
  margin-left: 2em;
}

.footerNavigationMainList ul {
  margin: 0px 15px 116px;
}

.footerNavigationMainList li {
  color: white;
  font-size: 15px;
  cursor: pointer;
}

.footerNavigationMainList a {
  text-decoration: none;
  color: white;
  font-size: 15px;
}

.footerNavigationSubscribe {
  margin-top: 51px;
  margin-right: 2em;
}

.footerNavigationSubscribeMainText {
  font-weight: 700;
  font-size: 20px;
}

.footerNavigationSubscribeSubText {
  font-weight: 400;
  font-size: 15px;
}

.footerNavigationSubscribeForm form {
  display: flex;
  flex-wrap: wrap;
}

.footerNavigationSubscribeInput {
  display: flex;
  flex-direction: column;
}

.footerNavigationSubscribeForm
  input[type="email"]:not(:focus):not(:placeholder-shown):valid {
  border-color: rgb(45, 157, 45);
  font-weight: 700;
}

.footerNavigationSubscribeForm
  input:not(:focus):not(:placeholder-shown):invalid {
  border-color: #ff9f1e;
}

.footerNavigationSubscribeForm
  input[type="submit"]:not(:focus):not(:placeholder-shown):invalid {
  border: 1px solid #ff9f1e;
}

.footerNavigationSubscribeForm input[type="email"] {
  padding: 5px;
  margin-right: 18.5px;
  font-size: 15px;
  width: 25em;
}

.footerNavigationSubscribeForm input[type="submit"] {
  max-height: 35px;
  border-radius: 0px;
  font-size: 15px;
  transition: 0.5s ease;
}

.footerNavigationSubscribeForm input[type="submit"]:hover {
  color: #0d4c86;
  border: 1px solid white;
  background-color: white;
  transition: 0.5s ease;
}

.footerNavigationSubscribeSocialMedia img {
  margin: 69px 22px 50px 0px;
}

.navbarFollowUsOnSocialMediaImgContainer img {
  margin: 0px 22px 0px 0px;
}

.footerCopyright {
  background-color: black;
  color: white;
}

.footerCopyrightContainer {
  text-align: left;
  max-width: 1440px;
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  padding-top: 28px;
  padding-bottom: 37px;
}

.footerCopyright p {
  padding: 8px;
  margin-left: 61px;
}

.footerCopyright ul {
  margin-right: 48px;
}

.footerCopyright li {
  color: white;
}

.swal2-title.subscriberTitle {
  font-size: 2.25em;
  font-weight: 600;
  color: #ff9f1e;
  padding-top: 0;
}

.swal2-html-container.subscriberText {
  font-size: 1.125em;
  font-weight: 400;
  color: #0d4c86;
  margin-bottom: 55px;
}

.swal2-image.subscriberImage {
  margin-bottom: 8px;
  max-width: 350px;
}

.footerNavigationSuccessIcon {
  visibility: hidden;
  position: relative;
  margin-top: -2em;
  max-width: 22px;
  right: -87%;
}

.footerNavigationError p {
  margin-top: 0.8em;
  font-size: 0.8em;
  color: #ff9f1e;
}

.footerNavigationAgreement p {
  margin-top: 1.5em;
  font-size: 0.6em;
}

.footerNavigationAgreement span {
  color: #ff9f1e;
  cursor: pointer;
}

/* Responsive */
.smallScreen {
  display: none;
}

@media (min-width: 821px) and (max-width: 1200px) {
  /* Home Banner */
  .textBanner {
    margin-left: 42px;
    margin-right: 42px;
    bottom: 15%;
  }

  .textBannerMain {
    font-size: 30px;
  }

  .textBannerSub {
    font-size: 18px;
  }

  .textBanner button {
    margin-top: 23px;
    font-size: 14px;
    padding: 8px;
  }

  #homeBanner img {
    width: 100%;
    height: 550px;
    object-fit: cover;
    /* filter: brightness(70%); */
  }
}

@media (min-width: 641px) and (max-width: 820px) {
  /* Disable horizontal scroll and remove white space */
  html,
  body {
    width: 100%;
    height: auto;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
  }
  /* Reusable title */
  .webTitle {
    font-size: 25px;
    margin-top: 20px;
    margin-left: 46px;
    margin-right: 46px;
  }

  /* Bootstrap container */
  div.col {
    padding: 0;
  }

  /* Navigation Bar */
  .navigationMobile {
    display: flex;
    justify-content: space-between;
    position: absolute;
    align-items: center;
  }

  .navigationMobile img {
    position: absolute;
    top: 2vh;
    left: 10px;
    width: 5.75em;
  }

  #homeBanner img {
    width: 100%;
    height: 370px;
    object-fit: cover;
    /* filter: brightness(70%); */
  }

  .bm-burger-button {
    position: fixed;
    width: 36px;
    height: 25px;
    right: 36px;
    top: 12vh;
  }

  .bm-overlay {
    width: 100%;
  }

  .bm-burger-bars {
    background: black;
    border-radius: 100px;
    max-height: 2px;
  }

  .bm-cross-button {
    height: 24px;
    width: 24px;
  }

  .bm-cross {
    background: white;
  }

  .bm-menu {
    background: #092e50;
    text-align: right;
    padding: 2.5em 1.5em 0;
    font-size: 1em;
  }

  .bm-menu a {
    margin-bottom: 30px;
    text-decoration: none;
    color: white;
  }

  /* Vision and Goal */
  #visionAndGoal {
    margin-top: 0;
    margin-bottom: 0;
  }

  .visionAndGoalText {
    font-size: 30px;
    margin-left: 72px;
    margin-right: 72px;
  }

  /* Side Banner */
  .sideBannerLeft,
  .sideBannerRight {
    max-height: 1000px;
  }

  .sideBannerRightContainer {
    justify-content: flex-end;
  }

  .sideBannerLeft {
    flex-direction: column;
  }

  .sideBannerRight {
    flex-direction: column;
  }

  .sideBannerLeft img,
  .sideBannerRight img {
    width: 100%;
  }

  .sideBannerLeftText,
  .sideBannerRightText {
    margin-left: 42px;
    margin-right: 83px;
    margin-bottom: 1em;
  }

  .sideBannerTextMain {
    font-size: 18px;
  }

  .sideBannerTextSub {
    font-size: 16px;
    color: #1f81dc;
    line-height: 30px;
  }

  /* Programs */
  #programs {
    padding-top: 1em;
  }

  .programsCard {
    margin-left: 1em;
    margin-right: 1em;
  }

  /* News */
  .newsCards {
    justify-content: center;
  }

  /* Clients */
  .clientsLogo {
    margin-left: 1em;
    margin-right: 1em;
  }

  /* Footer */
  #footer {
    max-width: 400px;
  }

  .footerUnGlobalImpact img {
    width: 30%;
  }

  .footerUnGlobalImpact p {
    text-align: center;
    font-size: 13px;
  }

  .footerNavigationMain {
    flex-wrap: nowrap;
  }

  .footerNavigationMainList {
    margin-left: 0;
  }

  .footerNavigationMainList ul {
    margin-bottom: 42px;
  }

  .footerNavigationSubscribe {
    margin-left: 30px;
    margin-top: 10px;
    margin-right: 42px;
  }

  .footerNavigationSubscribeForm input[type="email"] {
    font-size: 12px;
    width: 90%;
  }

  .footerCopyrightContainer {
    flex-direction: column-reverse;
  }

  .footerCopyright p,
  .footerCopyright ul {
    margin-left: 30px;
    padding-left: 0;
  }

  .footerCopyright li {
    padding-left: 0;
  }

  .footerNavigationSuccessIcon {
    display: none;
  }

  .footerNavigationSubscribeForm form {
    flex-direction: column;
  }

  .footerNavigationSubscribeForm input[type="email"] {
    margin-bottom: 1em;
  }

  .footerSubmit {
    width: 30%;
  }

  /* Responsive */
  .bigScreen {
    display: none;
  }
  .smallScreen {
    display: block;
  }

  /* Home Banner */
  .textBanner {
    margin-left: 42px;
    margin-right: 42px;
    bottom: 30%;
  }

  .homeBannerContainer {
    margin-top: 0vh;
  }

  .textBannerMain {
    font-size: 20px;
  }

  .textBannerSub {
    font-size: 14px;
  }

  .textBanner button {
    margin-top: 0px;
    font-size: 12px;
    padding: 8px;
  }
}

@media (min-width: 501px) and (max-width: 640px) {
  /* Disable horizontal scroll and remove white space */
  html,
  body {
    width: 100%;
    height: auto;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
  }
  /* Reusable title */
  .webTitle {
    font-size: 25px;
    margin-top: 20px;
    margin-left: 46px;
    margin-right: 46px;
  }

  /* Bootstrap container */
  div.col {
    padding: 0;
  }

  /* Navigation Bar */
  .navigationMobile {
    display: flex;
    justify-content: space-between;
    position: absolute;
    align-items: center;
  }

  .navigationMobile img {
    position: absolute;
    top: 2vh;
    left: 10px;
    width: 5.75em;
  }

  #homeBanner img {
    width: 100%;
    height: 370px;
    object-fit: cover;
    /* filter: brightness(70%); */
  }

  .bm-burger-button {
    position: fixed;
    width: 36px;
    height: 25px;
    right: 36px;
    top: 12vh;
  }

  .bm-overlay {
    width: 100%;
  }

  .bm-burger-bars {
    background: black;
    border-radius: 100px;
    max-height: 2px;
  }

  .bm-cross-button {
    height: 24px;
    width: 24px;
  }

  .bm-cross {
    background: white;
  }

  .bm-menu {
    background: #092e50;
    text-align: right;
    padding: 2.5em 1.5em 0;
    font-size: 1em;
  }

  .bm-menu a {
    margin-bottom: 30px;
    text-decoration: none;
    color: white;
  }

  /* Vision and Goal */
  #visionAndGoal {
    margin-top: 0;
    margin-bottom: 0;
  }

  .visionAndGoalText {
    font-size: 30px;
    margin-left: 72px;
    margin-right: 72px;
  }

  /* Side Banner */
  .sideBannerLeft,
  .sideBannerRight {
    max-height: 1000px;
  }

  .sideBannerRightContainer {
    justify-content: flex-end;
  }

  .sideBannerLeft {
    flex-direction: column;
  }

  .sideBannerRight {
    flex-direction: column;
  }

  .sideBannerLeft img,
  .sideBannerRight img {
    width: 100%;
  }

  .sideBannerLeftText,
  .sideBannerRightText {
    margin-left: 42px;
    margin-right: 83px;
    margin-bottom: 1em;
  }

  .sideBannerTextMain {
    font-size: 18px;
  }

  .sideBannerTextSub {
    font-size: 16px;
    color: #1f81dc;
    line-height: 30px;
  }

  /* Programs */
  #programs {
    padding-top: 1em;
  }

  .programsCard {
    margin-left: 1em;
    margin-right: 1em;
  }

  /* News */
  .newsCards {
    justify-content: center;
  }

  /* Clients */
  .clientsLogo {
    margin-left: 1em;
    margin-right: 1em;
  }

  /* Footer */
  #footer {
    max-width: 400px;
  }

  .footerUnGlobalImpact img {
    width: 30%;
  }

  .footerUnGlobalImpact p {
    text-align: center;
    font-size: 13px;
  }

  .footerNavigationMain {
    flex-wrap: nowrap;
  }

  .footerNavigationMainList {
    margin-left: 0;
  }

  .footerNavigationMainList ul {
    margin-bottom: 42px;
  }

  .footerNavigationSubscribe {
    margin-left: 30px;
    margin-top: 10px;
    margin-right: 42px;
  }

  .footerNavigationSubscribeForm input[type="email"] {
    font-size: 12px;
    width: 90%;
  }

  .footerCopyrightContainer {
    flex-direction: column-reverse;
  }

  .footerCopyright p,
  .footerCopyright ul {
    margin-left: 30px;
    padding-left: 0;
  }

  .footerCopyright li {
    padding-left: 0;
  }

  .footerNavigationSuccessIcon {
    display: none;
  }

  .footerNavigationSubscribeForm form {
    flex-direction: column;
  }

  .footerNavigationSubscribeForm input[type="email"] {
    margin-bottom: 1em;
  }

  .footerSubmit {
    width: 30%;
  }

  /* Responsive */
  .bigScreen {
    display: none;
  }
  .smallScreen {
    display: block;
  }

  /* Home Banner */
  .textBanner {
    margin-left: 42px;
    margin-right: 42px;
    bottom: 10%;
  }

  .homeBannerContainer {
    margin-top: 0vh;
  }

  .textBannerMain {
    font-size: 20px;
  }

  .textBannerSub {
    font-size: 14px;
  }

  .textBanner button {
    margin-top: 0px;
    font-size: 12px;
    padding: 8px;
  }
}

/* Mobile Resolution */
@media only screen and (max-width: 500px) {
  /* Reusable title */
  .webTitle {
    font-size: 25px;
    margin-top: 0px;
    margin-left: 46px;
    margin-right: 46px;
  }

  /* Home Banner */

  .homeBannerContainer {
    margin-top: 0;
  }

  .textBanner {
    margin-left: 20px;
    margin-right: 20px;
    bottom: 15%;
  }

  #visionAndGoal {
    margin-top: 0;
    margin-bottom: 0;
  }

  .textBannerMain {
    font-size: 14px;
  }

  .textBannerSub {
    font-size: 12px;
  }

  .textBanner button {
    margin-top: 0px;
    font-size: 10px;
  }

  /* Vision and Goal */
  .visionAndGoalText {
    font-size: 18px;
    margin-left: 42px;
    margin-right: 42px;
  }

  #programs {
    padding-top: 1em;
  }

  /* Programs */
  .programsEachCard p {
    font-size: 18px;
    max-width: 250px;
  }

  .programsEachCardSmallText {
    font-size: 16px;
    line-height: 5px;
  }

  /* Events */
  #events {
    margin: 0px 30px 30px 30px;
  }

  .eventsPagination {
    margin: 0;
  }

  /* Small Banner - Mentorship */
  .mentorshipContainer {
    padding: 27px 42px 40px 42px;
  }

  .mentorshipCollaboration img {
    width: 50%;
  }

  .mentorshipMainText p {
    font-size: 24px;
    margin-bottom: 20px;
  }

  .mentorshipMainButton {
    display: none;
  }

  /* News */
  .newsCards {
    justify-content: center;
  }

  .newsEachCard img {
    max-width: 400px;
  }

  .newsEachCard p {
    font-size: 15px;
  }

  .newsEachCardTextSub {
    font-size: 12px;
  }

  .newsSmallArrow {
    font-size: 25px;
  }

  /* Clients */
  .clientsLogo {
    margin-left: 10px;
    margin-right: 10px;
  }

  .clientsLogoContainer {
    width: 65%;
    height: auto;
    padding: 35px;
  }

  /* Videos */
  .videosSubtitle {
    font-size: 15px;
    line-height: 20px;
    margin-left: 47px;
    margin-right: 47px;
    margin-bottom: 20px;
  }

  .videosResponsive {
    width: 320px;
    height: 320px;
  }

  /* Footer */
  .swal2-title.subscriberTitle {
    font-size: 1.5em;
  }

  .swal2-html-container.subscriberText {
    font-size: 1em;
  }

  .swal2-image.subscriberImage {
    max-width: 250px;
  }

  .footerNavigationMain {
    flex-wrap: wrap;
  }

  /* Responsive */
  .bigScreen {
    display: none;
  }
  .smallScreen {
    display: block;
  }

  .followUsOnSMContainer {
    font-size: 13px;
  }

  .navigationBarMainContainer {
    position: fixed;
    z-index: 2;
    width: 100%;
    box-shadow: 0px 8px 30px rgba(0, 0, 0, 0.21);
    top: 0;
  }

  .navbarFollowUsOnSocialMediaContainer {
    display: flex;
    flex-direction: row;
    text-align: left;
    padding-top: 1.5vh;
    padding-bottom: 1.5vh;
    gap: 3vw;
    align-items: center;
    max-width: 1440px;
    width: 100%;
  }

  .navbarFollowUsOnSocialMediaImgContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8vw;
    /* margin-top: 0.5em; */
    margin-top: 0;
  }

  .navbarFollowUsOnSocialMediaImgContainer img {
    margin: 0px 0px 0px 0px;
    width: 20px;
    height: auto;
  }

  .fullWidthNavbarFollowUsOnSocialMedia {
    width: 100%;
    /* padding-left: 12vw;
    padding-right: 12vw; */
    padding-left: 6vw;
    padding-right: 6vw;
    background-color: #0c4477;
    color: white;
  }

  #home {
    padding-top: 12vh;
  }

  #homeBanner img {
    width: 100%;
    height: 250px;
    object-fit: cover;
    /* filter: brightness(70%); */
  }

  /* Navigation Bar */
  .navigationMobile {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #ffffff;
    width: 100%;
    height: 100%;
  }

  .navigationMobile img {
    width: 5.75em;
  }

  .bm-burger-button {
    position: relative;
    width: 36px;
    height: 25px;
    right: 20px;
  }

  .bm-overlay {
    width: 100%;
  }

  .bm-burger-bars {
    background: black;
    border-radius: 100px;
    max-height: 2px;
  }

  .bm-cross-button {
    height: 24px;
    width: 24px;
  }

  .bm-cross {
    background: white;
  }

  .bm-menu {
    background: #092e50;
    text-align: right;
    padding: 2.5em 1.5em 0;
    font-size: 1em;
  }

  .bm-menu a {
    margin-bottom: 30px;
    text-decoration: none;
    color: white;
  }

  .burgerMenuStyle {
    top: 0;
  }

  .footerNavigationMainList {
    margin-left: 0;
  }

  .footerNavigationMainList ul {
    margin-bottom: 42px;
  }

  .footerNavigationSubscribe {
    margin-top: 10px;
    margin-right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;
  }

  .footerNavigationSubscribeForm {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .footerNavigationSubscribeForm form {
    display: flex;
    justify-content: center;
    width: 100%;
    margin: 0;
    padding: 0;
  }

  .footerNavigationSubscribeInput {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .footerNavigationSubscribeForm
    input[type="email"]:not(:focus):not(:placeholder-shown):valid {
    border-color: rgb(45, 157, 45);
    font-weight: 700;
  }

  .footerNavigationSubscribeForm
    input:not(:focus):not(:placeholder-shown):invalid {
    border-color: #ff9f1e;
  }

  .footerNavigationSubscribeForm
    input[type="submit"]:not(:focus):not(:placeholder-shown):invalid {
    border: 1px solid #ff9f1e;
  }

  .footerNavigationSubscribeForm input[type="email"] {
    padding: 5px;
    margin-right: 20px;
    font-size: 15px;
    width: 20em;
  }

  .footerNavigationSubscribeForm input[type="submit"] {
    max-height: 35px;
    border-radius: 0px;
    font-size: 15px;
    transition: 0.5s ease;
  }

  .footerNavigationSubscribeForm input[type="submit"]:hover {
    color: #0d4c86;
    border: 1px solid white;
    background-color: white;
    transition: 0.5s ease;
  }

  .footerNavigationSubscribeSocialMedia img {
    margin: 0px;
    display: none;
  }

  .footerNavigationAgreement p {
    margin-top: 1.5em;
    font-size: 11px;
  }

  #sideBanner {
    display: flex;
    flex-direction: column;
    background-color: #fafafa;
  }

  .sideBannerLeft {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 60px;
    max-height: 2000px;
    overflow: hidden;
  }

  .sideBannerLeft img {
    width: 100%;
    object-position: top;
    object-fit: cover;
  }

  .sideBannerLeftContainer {
    display: flex;
    justify-content: space-between;
  }

  .sideBannerLeftText {
    margin: 2.5em;
    text-align: left;
    max-width: 100%;
  }

  .sideBannerTextMain {
    font-size: 30px;
    font-weight: 700;
    color: #0d4c86;
  }

  .sideBannerTextSub {
    font-size: 18px;
    color: #1f81dc;
    line-height: 40px;
  }

  .sideBannerRight {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row-reverse;
    max-height: 2000px;
    overflow: hidden;
  }

  .sideBannerRightContainer {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
  }

  .sideBannerRightText {
    margin: 2.5em;
    text-align: left;
    max-width: 100%;
  }

  .sideBannerRight img {
    width: 100%;
    object-fit: cover;
    object-position: top;
  }

  .sideBannerTextMain {
    font-size: 30px;
    font-weight: 700;
    color: #0d4c86;
  }

  .sideBannerTextSub {
    font-size: 18px;
    color: #1f81dc;
    line-height: 40px;
  }
}
