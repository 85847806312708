.diplomaESGMainContainer {
  display: flex;
  flex-direction: column;
  margin: 11vh 10vw 0 10vw;
}

.diplomaESGMainContainer img {
  object-fit: cover;
}

.dipPmESGDescContainer {
  margin-top: 5vh;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.dipPmESGDescEachContainer {
  width: 45%;
}

.dipPmESGDescEachContainerTitle {
  display: flex;
  gap: 1.5vw;
}

.dipPmESGDescEachContainerTitle .dipPmESGDescEachContainerTitleLabel {
  color: #0d4c86;
  font-size: 35px;
  font-weight: 600;
}

.dipPmESGDescEachContainerTitleHr {
  margin-top: 2vh;
  border: 1px solid #0d4c86;
}

.dipPmESGDescEachContainerDescription {
  margin-top: 2vh;
  font-size: 18px;
}

.buttonDiplomaESGContainer {
  display: flex;
  justify-content: center;
  margin-top: 4vh;
  margin-bottom: 4vh;
}

.buttonDiplomaESGContainer button {
  color: #ffffff;
  background-color: #0d4c86;
  font-size: 20px;
}

.buttonDiplomaESGContainer button:hover {
  color: #ff9f1e;
  background-color: #0d4c86;
  font-size: 20px;
  font-weight: bold;
}

.buttonDownloadHereDiplomaESGContainer {
  display: flex;
  justify-content: center;
  margin-top: 4vh;
  margin-bottom: 4vh;
}

.buttonDownloadHereDiplomaESGContainer button {
  color: #000000;
  background-color: #ffab3f;
  font-size: 20px;
}

.buttonDownloadHereDiplomaESGContainer button:hover {
  color: #000000;
  background-color: #ffab3f;
  font-size: 20px;
  font-weight: bold;
}

.scheduleESGContainer img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.interlinkImageContainer img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

@media only screen and (max-width: 350px) {
  .dipPmESGDescEachContainer {
    width: 100%;
  }

  .dipPmESGDescEachContainerTitle {
    display: flex;
    gap: 4vw;
  }

  .dipPmESGDescEachContainerTitle .dipPmESGDescEachContainerTitleLabel {
    color: #0d4c86;
    font-size: 30px;
    font-weight: 600;
  }
}

@media only screen and (min-device-width: 351px) and (max-width: 820px) {
  .dipPmESGDescEachContainer {
    width: 100%;
  }

  .dipPmESGDescEachContainerTitle {
    display: flex;
    gap: 2vw;
  }

  .dipPmESGDescEachContainerTitle .dipPmESGDescEachContainerTitleLabel {
    color: #0d4c86;
    font-size: 33px;
    font-weight: 600;
  }
}

@media only screen and (min-device-width: 821px) and (max-device-width: 1300px) {
}
