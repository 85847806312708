.overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.6);
    transition: opacity 500ms;
    visibility: hidden;
    opacity: 1;
    z-index: 1;
    overflow-y: auto;
}

.popup {
    margin: 5vh auto;
    padding: 3em;
    background: #0D4C86;
    border-radius: 5px;
    width: 50%;
    position: relative;
    transition: all 1s ease-in-out;
    z-index: 1;
}

.popupCloseOverlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.popupContent {
    font-weight: 700;
}

.popup p {
    color: #FF9F1E;
    font-size: 36px;
    font-weight: 600;
}

.popup .popupClose {
    position: absolute;
    top: 20px;
    right: 30px;
    transition: all 200ms;
    font-size: 30px;
    text-decoration: none;
    color: white;
}

.popupContent {
    color: white;
    font-weight: 400;
}

@media only screen and (max-width: 500px) {
    .popup {
        width: 80%;
        margin: 1em auto;
    }
}